// import { Schema, arrayOf } from "normalizr";
import { normalize, schema } from "normalizr";

const quote_item = new schema.Entity("quote_items");

const customer = new schema.Entity("customers", {
  idAttribute: "id",
});

const user = new schema.Entity("users", {
  idAttribute: "id",
});

const manager = new schema.Entity("managers", {
  idAttribute: "id",
});

const additional_approver = new schema.Entity(
  "additional_approvers",
  {
    manager,
  },
  {
    idAttribute: "id",
  }
);

const asset = new schema.Entity("assets", {
  idAttribute: "id",
});

const message = new schema.Entity("messages", {
  idAttribute: "id",
});

const contract = new schema.Entity("contracts", {
  idAttribute: "id",
});

const quote = new schema.Entity(
  "quotes",
  {
    quote_items: [quote_item],
    assets: [asset],
    messages: [message],
    user,
    additional_approvers: [additional_approver],
    manager,
  },
  {
    idAttribute: "id",
  }
);

export const Schemas = {
  USER: user,
  MESSAGE: message,
  MESSAGES: [message],
  MANAGER: manager,
  MANAGERS: [manager],
  QUOTES: {
    items: [quote],
  },
  QUOTE: quote,
  CONTRACTS: {
    items: [contract],
  },
  ADDITIONAL_APPROVERS: {
    items: [additional_approver],
  },
};
