import React, { PropTypes } from "react";
import get from "lodash/get";
import moment from "moment";
import numeral from "numeral";
import isEmpty from "lodash/isEmpty";
import Logo from "./OCLM-Logo-Horizontal.jpg";
import { formatQuoteNumber, getQuoteTotal } from "../../utils/quotes";
import "./Watson.css";
import { nl2br } from "../../utils/string";

export default function WatsonTemplate({
  id,
  manager = {},
  created_at,
  name,
  description,
  notes,
  quote_items = [],
  user,
}) {
  return (
    <div className="watson-container">
      <table width="100%">
        <tr>
          <td width="33%" />
          <td width="33%" style={{ textAlign: "center" }}>
            <img
              src="//www.oclm.com/images/quote-logo-watson.jpg"
              alt="O\'Connell Landscape Maintenance"
              height="125"
            />
          </td>
          <td width="33%" style={{ verticalAlign: "top", textAlign: "right" }}>
            <span className="watson-header">WATSON LAND COMPANY</span>
            <br />
            <span className="watson-subheader">Landscape Authorization Letter</span>
          </td>
        </tr>
      </table>

      <table className="watson-table">
        <thead>
          <tr className="watson-column">
            <td className="watson-column" colSpan="2">
              <div className="watson-client-wrapper">
                <span className="watson-hdr-black watson-bold">Quote To</span>

                {!Boolean(isEmpty(manager) || isEmpty(manager.management)) && (
                  <div>
                    <div className="watson-company-name break">{get(manager, "management.company_name")}</div>

                    <div className="watson-fullName">{manager.name}</div>

                    <div className="address">
                      <span className="street">{get(manager, "management.street_address")}</span>
                      <div className="zipcode-city">
                        {Boolean(manager.management) && (
                          <span>
                            {get(manager, "management.city")}, {get(manager, "management.zip")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {Boolean(isEmpty(manager) || isEmpty(manager.management)) && (
                  <p>Customer may not exist or is deleted</p>
                )}
              </div>
            </td>

            <td className="watson-column" colSpan="2">
              &nbsp;
            </td>

            <td className="watson-column">
              <div className="supplier-wrapper">
                <table>
                  <tr>
                    <td width="140">
                      <b>Quote #: </b>
                    </td>
                    <td> OCLM {formatQuoteNumber(id, created_at)}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date:</b>
                    </td>
                    <td>{moment(created_at).format("MM/DD/YYYY")}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Submitted By:</b>
                    </td>
                    <td>{user.fullName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Job Name: </b>
                    </td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Job Description: </b>
                    </td>
                    <td>{description}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </thead>
      </table>

      <table className="watson-approval" height="200">
        <tr>
          <td width="70">
            <div className="watson-square" />
          </td>
          <td valign="middle">
            <table width="600">
              <tr>
                <td width="130">
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Approval: </label>
                </td>
                <td width="200" className="watson-approver-line">
                  &nbsp;
                </td>
                <td width="10">
                  <label>Date: </label>
                </td>
                <td width="115" className="watson-date-line">
                  &nbsp;
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td />
          <td>
            <table width="600" align="center">
              <tr>
                <td width="130">
                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Approved By: </label>
                </td>
                <td width="200" className="watson-approver-line">
                  &nbsp;
                </td>
                <td width="10" align="right">
                  <label>Date: </label>
                </td>
                <td width="115" className="watson-date-line">
                  &nbsp;
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table className="watson-line-header">
        <tr>
          <td className="left col-line-number">
            <div className="watson-hdr-black watson-bold watson-padded">L#</div>
          </td>
          <td className="left col-location">
            <div className="watson-hdr-black watson-bold watson-padded">Location</div>
          </td>
          <td className="left col-quantity">
            <div className="watson-hdr-black watson-bold watson-padded">Quantity</div>
          </td>
          <td className="left col-unit">
            <div className="watson-hdr-black watson-bold watson-padded">Unit</div>
          </td>
          <td className="left col-desc">
            <div className="watson-hdr-black watson-bold watson-padded">Description</div>
          </td>
          <td className="right col-total">
            <div className="watson-hdr-black watson-bold watson-padded">Total</div>
          </td>
        </tr>
      </table>

      <table width="100%">
        {quote_items.map((obj, index) => {
          return (
            <tr className="watson-line-item" key={obj.id}>
              <td className="left col-line-number">{index + 1}</td>
              <td className="left col-location">{obj.location}&nbsp;</td>
              <td className="left col-quantity">{numeral(obj.quantity).format("0,0.00")}&nbsp;</td>
              <td className="left col-unit">{obj.type}&nbsp;</td>
              <td className="left col-desc">{obj.description}</td>
              <td className="right col-total">{numeral(obj.price * obj.quantity).format("$0,0.00")}&nbsp;</td>
            </tr>
          );
        })}
      </table>

      <div className="clear" />

      <div className="watson-total">
        <b>Total</b>:&nbsp;
        <span className="watson-bold">{numeral(getQuoteTotal((quote_items = []))).format("$0,0.00")}</span>
      </div>

      <div className="watson-quote-notice">
        THE ABOVED QUOTED PRICES ARE VALID FOR THIRTY DAYS FROM THE DATE OF QUOTE
      </div>

      <div className="watson-notes">
        <strong>Notes</strong>
        <br />
        {nl2br(notes)}
      </div>

      <div className="watson-notes">
        <p>
          By commencing the work described in this Authorization Letter, Contractor is agreeing to perform such work for
          the price and in accordance with the terms set forth in this Authorization Letter. This Authorization Letter
          is specifically subject to the terms and conditions contained in the existing in the existing Master Vendor
          Agreement between Watson Land Company and the Contractor. Except as expressley set forth herein, the Master
          Vendor Agreement is unchanged and unmodified and shall control and/or govern the work and/or services which
          are performed and/or supplied by the contractor.
        </p>
      </div>

      <div className="watson-receipt">
        <span className="watson-hdr-black watson-bold  watson-padded">
          WORK WILL COMMENCE WITHIN 30 DAYS OF RECEIPT OF A SIGNED COPY OF THIS LANDSCAPE AUTHORIZATION LETTER
        </span>
      </div>
    </div>
  );
}

WatsonTemplate.defaultProps = {
  customers: {},
  user: {},
};
