import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import cookie from "react-cookie";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";

import "./UniversalSearch.css";
import "./searchbar.css";

//
// Utils
//
function formatDate(date) {
  return moment(date).format("M/D/YYYY");
}

function getAcceptRejectText(acceptRejected, emailSentCount) {
  if (acceptRejected === null || acceptRejected === undefined) {
    if (emailSentCount > 0) {
      return "Awaiting approval";
    }
    return "In progress";
  }
  if (acceptRejected) {
    return "Accepted";
  }
  return "Rejected";
}

function getHighlighted(field, query) {
  field = upperFirst(field);
  const matches = AutosuggestHighlightMatch(field, query);
  const parts = AutosuggestHighlightParse(field, matches);
  const highlighted = parts.map((part, index) => {
    const className = part.highlight ? "highlight" : null;

    return (
      <span className={className} key={index}>
        {part.text}
      </span>
    );
  });
  return highlighted;
}

//
// Render Functions
//
function renderQuote(record, query) {
  const nameHighLighted = getHighlighted(record.name, query);
  const descriptionHighLighted = getHighlighted(record.description, query);
  const notesHighLighted = getHighlighted(record.notes, query);
  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
      <div className="suggestion-1-col">{descriptionHighLighted}</div>
      <div className="suggestion-1-col">{notesHighLighted}</div>
    </div>
  );
}
function renderUser(user, query) {
  const firstNameHighLighted = getHighlighted(user.first_name, query);
  const lastNameHighLighted = getHighlighted(user.last_name, query);
  const emailHighLighted = getHighlighted(user.email, query);
  const titleHighLighted = getHighlighted(user.title, query);
  const cellHighLighted = getHighlighted(user.cell, query);

  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">
        {firstNameHighLighted} {lastNameHighLighted}
      </div>
      <div className="suggestion-1-col">{titleHighLighted}</div>
      <div className="suggestion-1-col">
        {emailHighLighted} {cellHighLighted}
      </div>
    </div>
  );
}
function renderJob(job, query) {
  const jobNameHighLighted = getHighlighted(job.project_name, query);
  const legalNameHighLighted = getHighlighted(job.legal_name, query);
  const streetAddressHighLighted = getHighlighted(job.street_address, query);
  const cityHighLighted = getHighlighted(job.city, query);
  const zipHighLighted = getHighlighted(job.zip, query);

  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{jobNameHighLighted}</div>
      <div className="suggestion-1-col">{legalNameHighLighted}</div>
      <div className="suggestion-1-col">
        {streetAddressHighLighted}
        {job.street_address && job.city ? "," : ""} {cityHighLighted} {zipHighLighted}
      </div>
    </div>
  );
}
function renderManagement(management, query) {
  const companyNameHighLighted = getHighlighted(management.company_name, query);
  const streetAddressHighLighted = getHighlighted(management.street_address, query);
  const cityHighLighted = getHighlighted(management.city, query);
  const zipHighLighted = getHighlighted(management.zip, query);

  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{companyNameHighLighted}</div>
      <div className="suggestion-1-col">
        {streetAddressHighLighted}
        {management.street_address && management.city ? "," : ""} {cityHighLighted} {zipHighLighted}
      </div>
    </div>
  );
}
function renderManager(user, query) {
  const nameHighLighted = getHighlighted(user.name, query);
  const emailHighLighted = getHighlighted(user.email, query);
  const phoneHighLighted = getHighlighted(user.phone, query);
  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
      <div className="suggestion-1-col">{emailHighLighted}</div>
      {user.phone && (
        <div className="suggestion-1-col">
          {"Phone: "} {phoneHighLighted}
        </div>
      )}
    </div>
  );
}
function renderLaborRate(laborRate, query) {
  const nameHighLighted = getHighlighted(laborRate.name, query);
  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
    </div>
  );
}
function renderPricingGroup(pricingGroup, query) {
  const nameHighLighted = getHighlighted(pricingGroup.name, query);
  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
    </div>
  );
}

function renderContract(contract, query) {
  const nameHighLighted = getHighlighted(contract.name, query);
  const emailHighLighted = getHighlighted(contract.email, query);
  const companyNameHighLighted = getHighlighted(contract.company_name, query);
  const effectiveDate = contract.effective_date;
  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
      <div className="suggestion-1-col">{emailHighLighted}</div>
      {effectiveDate && (
        <div className="suggestion-1-col">
          {"Effective: "} {formatDate(effectiveDate)}
        </div>
      )}
      {contract.company_name && <div className="suggestion-1-col">{companyNameHighLighted}</div>}
    </div>
  );
}
function renderRaiseRequest(raiseRequest, query) {
  const nameHighLighted = getHighlighted(raiseRequest.name, query);
  const templateNameHighLighted = getHighlighted(raiseRequest.template_name, query);
  const acceptRejectText = getAcceptRejectText(raiseRequest.accept_rejected);

  return (
    <div className={"suggestion-content"}>
      <div className="suggestion-content-header">{nameHighLighted}</div>
      {raiseRequest.template_name && <div className="suggestion-1-col">{templateNameHighLighted}</div>}
      <div className="suggestion-1-col">{acceptRejectText}</div>
    </div>
  );
}

// custom renderer for section
function renderSuggestion(suggestion, { query }) {
  const { resourcetype } = suggestion;

  if (resourcetype === "user") {
    return renderUser(suggestion, query);
  } else if (resourcetype === "manager") {
    return renderManager(suggestion, query);
  } else if (resourcetype === "job") {
    return renderJob(suggestion, query);
  } else if (resourcetype === "management") {
    return renderManagement(suggestion, query);
  } else if (resourcetype === "labor_rate") {
    return renderLaborRate(suggestion, query);
  } else if (resourcetype === "pricing_group") {
    return renderPricingGroup(suggestion, query);
  } else if (resourcetype === "contract") {
    return renderContract(suggestion, query);
  } else if (resourcetype === "raise_request") {
    return renderRaiseRequest(suggestion, query);
  } else if (resourcetype === "quote") {
    return renderQuote(suggestion, query);
  }

  const suggestionText = `${suggestion.firstName}`;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <span className={`suggestion-content ${suggestion.twitter}`}>
      <span className="name">
        {parts.map((part, index) => {
          const className = part.highlight ? "highlight" : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    </span>
  );
}

function renderSectionTitle(section) {
  return <strong>{startCase(section.title)}</strong>;
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

class UniversalSearch extends Component {
  state = {
    inputValue: "",
    suggestions: [],
  };

  onChange = (event, { newValue }) => {
    this.setState({
      inputValue: newValue,
    });
  };

  getData = (input) => {
    return axios
      .get(`${process.env.REACT_APP_PORTAL_API_URL}/search?query=${input}`, {
        headers: {
          Authorization: `Bearer ${cookie.load("token")}`,
        },
      })
      .then((response) => response.data)
      .then((suggestions) =>
        this.setState({
          suggestions,
        })
      );
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { resourcetype, id } = suggestion;
    let route = "";
    switch (resourcetype) {
      case "user":
        route = "user";
        break;
      case "job":
        route = "jobsetup";
        break;
      case "management":
        route = "management";
        break;
      case "manager":
        route = "manager";
        break;
      case "labor_rate":
        route = "laborrate";
        break;
      case "pricing_group":
        route = "pricinggroup";
        break;
      case "contract":
        route = "contract";
        break;
      case "raise_request":
        route = "raiserequest";
        break;
      default:
        route = "";
    }

    this.onSuggestionsClearRequested();

    if (resourcetype === "quote") {
      window.location.href = `/dashboard/quotes/view/${id}`;
      // this.props.dispatch(push(`/dashboard/quotes/view/${id}`));
    } else {
      window.location.href = `${process.env.REACT_APP_PORTAL_APP_URL}/#/${route}/${id}/show`;
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getData(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onShouldRenderSuggestions(value) {
    return value.length > 2;
  }

  render() {
    const { inputValue, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Search",
      value: inputValue,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <div className="universal-search-container">
        <Autosuggest
          multiSection
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          shouldRenderSuggestions={this.onShouldRenderSuggestions}
          getSuggestionValue={() => inputValue}
          renderSectionTitle={renderSectionTitle}
          onSuggestionSelected={this.onSuggestionSelected}
          getSectionSuggestions={getSectionSuggestions}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

export default connect()(UniversalSearch);
