import React, { useState, useEffect } from "react";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";

import { API_URL } from "../../constants";

function getValueByType(type = "manager", data) {
  switch (type) {
    case "management":
      return {
        value: data.value,
        label: `${data.company_name} (${data.street_address}, ${data.city}, ${data.zip})`,
      };
    default:
      return {
        value: data.value,
        label: `${data.name} (${data.management.company_name})`,
      };
  }
}

const CustomerSearch = ({ field, className, value, type = "manager", searchField = "name", ...rest }) => {
  const [selected, setSelected] = useState(null);

  const getData = async (input) => {
    if (input) {
      try {
        const response = await axios.get(
          `${API_URL}/api/search?q=${input}&type=${type}&field=${searchField}&limit=1000`
        );
        const mappedData = response.data.map((resp) => getValueByType(type, resp));
        return mappedData;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    } else {
      if (value) {
        try {
          const resp = await axios.get(`${API_URL}/api/search?q=${value}&type=${type}&field=id&limit=1000`);
          if (resp && resp.data && resp.data.length) {
            const item = resp.data[0];
            setSelected(getValueByType(type, item));
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  return (
    <AsyncSelect
      {...rest}
      value={selected}
      defaultOptions
      loadOptions={getData}
      onChange={(val) => {
        if (val) {
          setSelected(val);
          rest.onChange(val);
        }
      }}
    />
  );
};

export default CustomerSearch;
