import * as ActionTypes from "../actions";

import { routerReducer as routing } from "react-router-redux";
import { combineReducers } from "redux";

import app from "./app";
import auth from "./auth";
import entities from "./entities";
import quotes from "./quotes";
import contracts from "./contracts";
import users from "./users";
import user from "./user";
import dashboard from "./dashboard";

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return action.error;
  }

  return state;
};

const rootReducer = combineReducers({
  app,
  dashboard,
  entities,
  errorMessage,
  routing,
  auth,
  quotes,
  contracts,
  users,
  user,
});

export default rootReducer;
