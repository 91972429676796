import React, { Component, useState } from "react";
import shortid from "shortid";
import get from "lodash/get";
import findIndex from "lodash/findIndex";

import { useForm } from "@tanstack/react-form";

import AddItems from "../QuoteItems/AddItems";
import { CustomText, CustomTextArea } from "../UI";
import CreateCustomer from "../Customers/Create";
import CustomerSearch from "../Customers/CustomerSearch";
import QuoteTotal from "./QuoteTotal";
import Uploader from "../Uploader";
import { getQuoteTotal } from "../../utils/quotes";
import { GridRow, GridColumn, Grid, Button, Icon, Input, Dropdown, TextArea } from "semantic-ui-react";
import { IMAGES_HOST } from "../../constants";

import * as validators from "./validators";

function handleCancel(state) {
  return {
    addNewCustomer: !state.addNewCustomer,
  };
}

function FieldInfo({ field }) {
  return (
    <>
      {field.state.meta.touchedErrors ? <em>{field.state.meta.touchedErrors}</em> : null}
      {field.state.meta.isValidating ? "Validating..." : null}
    </>
  );
}

function QuoteForm(props) {
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const { values } = props;

  const form = useForm({
    defaultValues: {
      name: values.name,
      description: values.description,
      notes: values.notes,
      // portal_manager_id
      // name: "",
      // description: "",
      // notes: ""
      // firstName: "",
      // lastName: "",
      quote_items: values.quotes,
      template_type: 1,
      images: [],
    },
    onSubmit: async ({ value }) => {
      // Do something with form data
      console.log(value);
    },
  });

  const quoteTotal = getQuoteTotal(values.quotes);

  function onCreateNewCustomer() {
    // this.props.setValue("portal_manager_id", null);
    // this.props.setValue("is_adding_new_manager", true);
    // this.setState({
    //   addNewCustomer: true,
    // });
    setAddNewCustomer(true);
  }

  function onAddItem() {
    const { quotes = [] } = props.values;
    const len = quotes.length;

    const lastOrder = len ? quotes[len - 1].order : -1;

    // this.props.addValue("quotes", {
    //   id: shortid.generate(),
    //   isNew: true,
    //   order: lastOrder + 1,
    // });
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(form.state.values, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values.quotes, null, 2)}</pre> */}
      <form.Provider>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          {/* Search Existing Customers */}
          {Boolean(!addNewCustomer) && (
            <div>
              <form.Field
                name="portal_manager_id"
                children={(field) => (
                  <CustomerSearch
                    value={field.state.value}
                    field="portal_manager_id"
                    placeholder="Type here to Search Existing Customers"
                    onChange={(val) => {
                      console.log("on change", val);
                      field.handleChange(val.value);
                    }}
                  />
                )}
              />

              <p className="help-text">
                Use the search above to associate an existing Customer.
                <br />
                If this is a new Customer, click the button below to add a new Customer.
              </p>
              {/* <button type="button" onClick={onCreateNewCustomer}>
                Click to Create New Customer
              </button> */}
            </div>
          )}

          {/* General Information */}
          <section className="quote-section">
            <h3>General Information</h3>

            <form.Field
              name="name"
              children={(field) => (
                <div className="quote-title-column">
                  <Input
                    fluid
                    label="Job Name"
                    placeholder="Ladera, Meadow Creek, etc..."
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            />

            <form.Field
              name="description"
              children={(field) => (
                <div className="quote-title-column">
                  <Input
                    fluid
                    label="Job Description"
                    placeholder="Planting, Turf Repair, etc..."
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                  <FieldInfo field={field} />
                </div>
              )}
            />

            <div className="ui fluid labeled input">
              <div className="ui label label quote-notes-label">Job Notes</div>
              <form.Field
                name="notes"
                children={(field) => (
                  <div className="quote-notes">
                    <TextArea
                      placeholder="Add notes about this quote – will be included on the Quote in the 'notes' section"
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                    <FieldInfo field={field} />
                  </div>
                )}
              />
            </div>
          </section>

          {/* Quote Items */}
          <section className="quote-section">
            <h3>Quote Items</h3>
            {/* <FormError field="quotes" /> */}
            <form.Field
              name="quote_items"
              mode="array"
              children={(quoteItemsField) => (
                <section className="add-item-container">
                  <Grid columns={16} stackable>
                    {!quoteItemsField.state.value.length
                      ? "No Quote Items"
                      : quoteItemsField.state.value.map((_, i) => (
                          <GridRow key={i} style={{ borderBottom: "1px solid #eaeaea" }}>
                            <GridColumn computer={11} tablet={16}>
                              <quoteItemsField.Field
                                index={i}
                                name="location"
                                children={(field) => {
                                  return (
                                    <div className="add-item location quote-title-column">
                                      <Input
                                        fluid
                                        label="Location"
                                        placeholder="Ladera, Meadow Creek, etc..."
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                      />
                                      <FieldInfo field={field} />
                                    </div>
                                  );
                                }}
                              />

                              <quoteItemsField.Field
                                index={i}
                                name="description"
                                children={(field) => {
                                  return (
                                    <div className="add-item description quote-title-column">
                                      <Input
                                        fluid
                                        placeholder="Description"
                                        label="Description"
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                      />
                                      <FieldInfo field={field} />
                                    </div>
                                  );
                                }}
                              />
                            </GridColumn>

                            <GridColumn computer={4} tablet={8}>
                              <quoteItemsField.Field
                                index={i}
                                name="quantity"
                                children={(field) => {
                                  return (
                                    <div className="add-item quote-title-column">
                                      <Input
                                        size="mini"
                                        label="Quantity"
                                        fluid
                                        placeholder="Number of Units"
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                      />
                                      <FieldInfo field={field} />
                                    </div>
                                  );
                                }}
                              />

                              <quoteItemsField.Field
                                index={i}
                                name="type"
                                children={(field) => {
                                  return (
                                    <div className="add-item quote-title-column">
                                      <Input
                                        size="mini"
                                        label="Type"
                                        fluid
                                        placeholder="Type ex: ea, lb, oz, ..."
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                      />
                                      <FieldInfo field={field} />
                                    </div>
                                  );
                                }}
                              />

                              <quoteItemsField.Field
                                index={i}
                                name="price"
                                children={(field) => {
                                  return (
                                    <div className="add-item quote-title-column">
                                      <Input
                                        fluid
                                        size="mini"
                                        label="Price"
                                        placeholder="Unit Price"
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                      />
                                      <FieldInfo field={field} />
                                    </div>
                                  );
                                }}
                              />
                            </GridColumn>

                            <GridColumn computer={1} tablet={8}>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <Button style={{ margin: "auto" }} color="orange" icon onClick={Function.prototype}>
                                  <Icon name="toggle up" />
                                </Button>
                                <Button style={{ margin: "auto" }} color="yellow" icon onClick={Function.prototype}>
                                  <Icon name="toggle down" />
                                </Button>
                                <Button style={{ margin: "auto" }} color="red" icon onClick={Function.prototype}>
                                  <Icon name="close" />
                                </Button>
                              </div>
                            </GridColumn>
                          </GridRow>
                        ))}
                    <GridRow style={{ borderBottom: "1px solid #eaeaea" }}>
                      <GridColumn width={16}>
                        <button
                          style={{ float: "right" }}
                          type="button"
                          onClick={() => {
                            quoteItemsField.pushValue({
                              id: shortid.generate(),
                              isNew: true,
                              order: 1,
                            });
                          }}
                        >
                          Add Quote Item
                        </button>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </section>
              )}
            />

            <QuoteTotal approval_user_id={values.approval_user_id} quotes={values.quotes} total={quoteTotal} />
          </section>

          <section className="quote-section">
            <h3>Extras</h3>
            <Dropdown
              value={form.state.values.template_type}
              placeholder="Quote Template"
              onChange={(e, data) => {
                // this.props.setValue("template_type", data.value);
                form.setFieldValue("template_type", data.value);
              }}
              options={[
                {
                  text: "Default",
                  value: 1,
                },
                {
                  text: "Watson",
                  value: 2,
                },
                {
                  text: "Ladera",
                  value: 3,
                },
                {
                  text: "Ocean Hills",
                  value: 4,
                },
              ]}
            />
          </section>

          {/* Uploaer  */}
          <Uploader
            images={form.state.values.images}
            onDrop={(items) => {
              // items.forEach((img) => addValue("images", img));
            }}
            // onClose={this.onCloseUploader}
            onAddCaption={(e, data, idx) => {
              // setValue(`images[${idx}].caption`, data.value);
            }}
          />

          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <button type="submit" disabled={!canSubmit}>
                {isSubmitting ? "..." : "Submit"}
              </button>
            )}
          />
        </form>
      </form.Provider>
    </div>
  );
}

export default QuoteForm;

// class QuoteForm extends Component {
//   state = {
//     addNewCustomer: false,
//   };

//   onAddItem = () => {
//     const { quotes = [] } = this.props.values;
//     const len = quotes.length;

//     const lastOrder = len ? quotes[len - 1].order : -1;

//     this.props.addValue("quotes", {
//       id: shortid.generate(),
//       isNew: true,
//       order: lastOrder + 1,
//     });
//   };

//   onDeleteItem = (value, index) => {
//     const { quotes } = this.props.values;

//     if (value.isNew) {
//       this.props.setValue("quotes", [...quotes.slice(0, index), ...quotes.slice(index + 1)]);
//       return;
//     }

//     this.props.addValue("quotesDeleted", value);

//     this.props.setValue("quotes", [...quotes.slice(0, index), ...quotes.slice(index + 1)]);
//   };

//   onCloseUploader = (obj, idx) => {
//     this.props.addValue("deletePictures", obj);
//     this.props.removeValue("images", idx);
//   };

//   onMoveUp = (value, idx) => {
//     const { quotes } = this.props.values;

//     // check to see if we aren't the first one
//     if (idx === 0) {
//       return false;
//     }

//     // safe to proceed
//     this.props.setValue("quotes", [
//       ...quotes.slice(0, idx - 1),
//       quotes[idx],
//       quotes[idx - 1],
//       ...quotes.slice(idx + 1),
//     ]);
//   };

//   onMoveDown = (value, idx) => {
//     const { quotes } = this.props.values;

//     // check to see if we aren't the last one
//     if (idx + 1 === quotes.length) {
//       return false;
//     }

//     // safe to proceed
//     this.props.setValue("quotes", [...quotes.slice(0, idx), quotes[idx + 1], quotes[idx], ...quotes.slice(idx + 2)]);
//   };

//   onCreateNewCustomer = () => {
//     this.props.setValue("portal_manager_id", null);
//     this.props.setValue("is_adding_new_manager", true);
//     this.setState({
//       addNewCustomer: true,
//     });
//   };

//   /*
//    * Called when you hit 'Cancel' when creating a new customer
//    */
//   onCancelCreateCustomer = () => {
//     this.props.setValue("is_adding_new_manager", false);
//     this.props.setValue("company_id", null);
//     this.props.setValue("customer", null);
//     this.setState(handleCancel);
//   };

//   render() {
//     const { submitForm, addValue, values, setValue, isEditing } = this.props;
//     const { addNewCustomer } = this.state;

//     const images = values.images.map((val) => {
//       return !!val.preview
//         ? val
//         : {
//             ...val,
//             path: `${IMAGES_HOST}/${val.key}?w=250&h=250&fit=fill&fill-color=FFF`,
//           };
//     });

//     const { quotes } = values;

//     // get the quote total
//     const quoteTotal = getQuoteTotal(quotes);

//     return (
//       <form onSubmit={submitForm}>
//         {addNewCustomer && <CreateCustomer field="create" onCancel={this.onCancelCreateCustomer} />}

//         {Boolean(!addNewCustomer) && (
//           <div>
//             <CustomerSearch
//               value={values.portal_manager_id}
//               field="portal_manager_id"
//               placeholder="Type here to Search Existing Customers"
//             />
//             <p className="help-text">
//               Use the search above to associate an existing Customer.
//               <br />
//               If this is a new Customer, click the button below to add a new Customer.
//             </p>
//             <button type="button" onClick={this.onCreateNewCustomer}>
//               Click to Create New Customer
//             </button>
//           </div>
//         )}

//         <section className="quote-section">
//           <h3>General Information</h3>
//           <CustomText
//             fluid
//             value={values.name}
//             className="quote-title-column"
//             field="name"
//             label="Job Name"
//             placeholder="Ladera, Meadow Creek, etc..."
//             fullWidth={true}
//           />
//           <CustomText
//             fluid
//             value={values.description}
//             className="quote-title-column"
//             field="description"
//             label="Job Description"
//             placeholder="Planting, Turf Repair, etc..."
//             fullWidth={true}
//           />
//           <div className="ui fluid labeled input">
//             <div className="ui label label quote-notes-label">Job Notes</div>
//             <CustomTextArea
//               value={values.notes}
//               className="quote-notes"
//               field="notes"
//               placeholder='Add notes about this quote – will be included on the Quote in the "notes" section'
//             />
//           </div>
//         </section>

//         <section className="quote-section">
//           <h3>Quote Items</h3>
//           <FormError field="quotes" />
//           {!values.quotes || !values.quotes.length
//             ? "There are no quote items"
//             : get(values, "quotes", [])
//                 .filter((obj) => !obj.isDeleted)
//                 .map((obj, i) => (
//                   <AddItems
//                     field={"quotes"}
//                     value={obj}
//                     key={obj.id}
//                     index={findIndex(values.quotes, ["id", obj.id])}
//                     setValue={setValue}
//                     onDeleteItem={this.onDeleteItem}
//                     onMoveUp={this.onMoveUp}
//                     onMoveDown={this.onMoveDown}
//                   />
//                 ))}

//           <QuoteTotal approval_user_id={values.approval_user_id} quotes={values.quotes} total={quoteTotal} />

//           <button type="button" onClick={this.onAddItem}>
//             Add Item
//           </button>
//         </section>

//         <section className="quote-section">
//           <h3>Extras</h3>
//           <Dropdown
//             value={values.template_type}
//             placeholder="Quote Template"
//             onChange={(e, data) => {
//               this.props.setValue("template_type", data.value);
//             }}
//             options={[
//               {
//                 text: "Default",
//                 value: 1,
//               },
//               {
//                 text: "Watson",
//                 value: 2,
//               },
//               {
//                 text: "Ladera",
//                 value: 3,
//               },
//               {
//                 text: "Ocean Hills",
//                 value: 4,
//               },
//             ]}
//           />
//         </section>

//         <Uploader
//           images={images}
//           onDrop={(items) => {
//             items.forEach((img) => addValue("images", img));
//           }}
//           onClose={this.onCloseUploader}
//           onAddCaption={(e, data, idx) => {
//             setValue(`images[${idx}].caption`, data.value);
//           }}
//         />

//         <button className="btnCreateQuote">{this.props.submitLabel}</button>
//       </form>
//     );
//   }
// }

/**
 * Form Validator
 **/
// export default Form({
//   defaultValues: {
//     quotes: [],
//     template_type: 1,
//     is_adding_new_manager: false,
//   },

//   validate: ({
//     approval_user_id,
//     customer,
//     portal_manager_id,
//     company_id,

//     name,
//     description,
//     notes,
//     quotes = [],
//     is_adding_new_manager,
//   }) => {
//     return {
//       name: !name ? "Job Name Required" : undefined,

//       portal_manager_id: validators.validateCustomerID(customer, portal_manager_id, company_id, is_adding_new_manager),

//       company_id: validators.validateCompanyID(customer, portal_manager_id, company_id, is_adding_new_manager),

//       // New Customer Stuff
//       customer: validators.validateCustomer(customer, portal_manager_id, company_id, is_adding_new_manager),

//       description: !description ? "Job Description Required" : undefined,

//       quotes: validators.validateQuotes(quotes),
//     };
//   },
// })(QuoteForm);
