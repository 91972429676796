import React from "react";
import moment from "moment";
import { Link } from "react-router";

import { Table } from "semantic-ui-react";
import { toTitleCase } from "../../utils/string";
import { formatQuoteNumber } from "../../utils/quotes";
import { getApprovalStatus } from "../../utils/quotes";

function quoteCanSubmit(approval_id) {
  if ([1, 2, 3, 4, 6].includes(approval_id)) {
    return false;
  }
  return true;
}

export const renderUser = (user) => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.email}>
                <Table.Cell>
                  {item.first_name} {item.last_name}
                </Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>

                <Table.Cell>
                  <Link to={`/#/portal/quotes/view/${item.id}`}>View</Link>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderManager = (user) => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.phone}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>

                <Table.Cell>
                  <a href={`${process.env.REACT_APP_PORTAL_APP_URL}/#/manager/${item.id}/show`}>View</a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderManagement = (user) => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.company_name}>
                <Table.Cell>{item.company_name}</Table.Cell>
                <Table.Cell>
                  {item.street_address}, {item.city} {item.state}
                </Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>

                <Table.Cell>
                  <a href={`${process.env.REACT_APP_PORTAL_APP_URL}/#/management/${item.id}/show`}>View</a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderRate = (user, slug = "laborrate") => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>
                <Table.Cell>
                  <a href={`${process.env.REACT_APP_PORTAL_APP_URL}/#/${slug}/${item.id}/show`}>View</a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderJob = (user) => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.project_name}>
                <Table.Cell>{item.project_name}</Table.Cell>
                <Table.Cell>
                  {item.street_address}, {item.city}, {item.zip}
                </Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>
                <Table.Cell>
                  <a href={`${process.env.REACT_APP_PORTAL_APP_URL}/#/jobsetup/${item.id}/show`}>View</a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderContract = (user) => {
  return (
    <div key={user.title} className="SearchPage--container">
      <h2>{toTitleCase(user.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Company Name</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.suggestions.map((item) => {
            return (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.company_name}</Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>

                <Table.Cell>
                  <a href={`${process.env.REACT_APP_PORTAL_APP_URL}/#/contract/${item.id}/show`}>View</a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export const renderQuotes = (result) => {
  return (
    <div key={result.title} className="SearchPage--container">
      <h2>{toTitleCase(result.title)} Results</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Quote No.</Table.HeaderCell>
            <Table.HeaderCell>Job Name</Table.HeaderCell>

            <Table.HeaderCell style={{ width: "200px" }}>Status</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {result.suggestions.map((item) => {
            const status = getApprovalStatus(item.approval_id, item.approval_user_id, item.manager_approved_on);
            return (
              <Table.Row>
                <Table.Cell>
                  <Link to={`/dashboard/quotes/view/${item.id}`}>{formatQuoteNumber(item.id, item.created_at)}</Link>
                </Table.Cell>
                <Table.Cell>
                  {item.name}
                  <br />
                  <span className="SearchPage--description">{item.description}</span>
                </Table.Cell>
                <Table.Cell className="quote-item-status">
                  <div className={`${status.className} tag-status`}>{status.label}</div>
                </Table.Cell>
                <Table.Cell>{moment(item.created_at).format("YYYY-MM-DD")}</Table.Cell>

                <Table.Cell textAlign="center">
                  <ul className="action-list">
                    <li>
                      <Link to={`/dashboard/quotes/view/${item.id}`}>View</Link> |
                    </li>
                    {quoteCanSubmit(item.approval_id) && (
                      <li>
                        <Link to={`/dashboard/quotes/submit/${item.id}`}>Submit</Link>
                      </li>
                    )}
                  </ul>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
