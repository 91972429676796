import { push } from "react-router-redux";
import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import qs from "querystring";

export const submitQuote = createAPIAction(
  "QUOTE_SUBMIT",
  "POST",
  "/quotes/submit",
  (data) => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, { response }) {
      dispatch(push("/dashboard/quotes"));
    },
  })
);

export const saveQuote = createAPIAction(
  "QUOTE_SAVE",
  "POST",
  "/quotes/save",
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export const copyQuote = createAPIAction(
  "QUOTE_COPY",
  "POST",
  (id) => `/quotes/copy/${id}`,
  null,
  () => ({
    schema: {
      copy: Schemas.QUOTE,
    },
    authenticated: true,
    onSuccess(dispatch, { response }) {
      const { copy: quoteId } = response.result;
      dispatch(push(`/dashboard/quotes/view/${quoteId}`));
    },
  })
);

export const downloadQuote = createAPIAction(
  "QUOTE_DOWNLOAD",
  "POST",
  (id) => `/quotes/download/${id}`,
  null,
  () => ({
    authenticated: true,
  })
);

export const submitForApproval = createAPIAction(
  "SUBMIT_FOR_APPROVAL",
  "POST",
  "/quotes/submit-manager-approval",
  (quote_id, approval_user_id) => ({
    quote_id,
    approval_user_id,
  }),
  () => ({
    authenticated: true,
  })
);

export const approveQuote = createAPIAction(
  "QUOTE_APPROVAL",
  "POST",
  (id) => `/quotes/approve/${id}`,
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export const denyQuote = createAPIAction(
  "QUOTE_DENY",
  "GET",
  (id) => `/quotes/deny/${id}`,
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export const managerApproveQuote = createAPIAction(
  "QUOTE_APPROVE",
  "POST",
  () => `/quotes/managerapprove`,
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export const getQuotes = createAPIAction(
  "QUOTES",
  "GET",
  (data) => {
    const filteredData = pickBy(data, identity);
    const querystring = qs.stringify(filteredData);
    return `/quotes?${querystring}`;
  },
  null,
  () => ({
    authenticated: true,
    schema: Schemas.QUOTES,
  })
);

export const getQuoteById = createAPIAction(
  "QUOTEBYID",
  "GET",
  (id) => `/quotes/${id}`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.QUOTE,
  })
);

export const createQuote = createAPIAction(
  "CREATE_QUOTE",
  "POST",
  "/quotes",
  (data) => data,
  () => ({
    authenticated: true,
    // onSuccess(dispatch, action) {
    //   const quoteId = action.response.id;
    //   console.log("got the id", quoteId);
    //   dispatch(push(`/dashboard/quotes/view/${quoteId}`));
    // }
  })
);

// CRUD update
export const crudUpdateQuote = createAPIAction(
  "CRUD_UPDATE_QUOTE",
  "PUT",
  (id) => `/quotes/crud/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
  })
);

// mark a quote as COMPLETED
export const completeQuote = createAPIAction(
  "COMPLETE_QUOTE",
  "PUT",
  (id) => `/quotes/complete/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
  })
);

export const editQuote = createAPIAction(
  "EDIT_QUOTE",
  "PUT",
  (id) => `/quotes/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, action) {
      // const { id } = action.response.model;
      // dispatch(push(`/dashboard/quotes/view/${id}`));
    },
  })
);

export const deleteQuote = createAPIAction(
  "QUOTE",
  "DELETE",
  (id) => `/quotes/${id}`,
  null,
  () => ({
    authenticated: true,
  })
);

export const logout = createAction("LOGOUT");
